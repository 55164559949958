<template>
  <div class="demo-div">
    <div class="demo-wrapper">
      <h2>Try creating</h2>
      <div class="carousel">
        <p
          id="payment-link-opt"
          :class="[!selected ? 'active' : '']"
          @click="toggle"
        >
          Payment Link
        </p>
        <p
          id="invoices-opt"
          :class="[selected ? 'active' : '']"
          @click="toggle"
        >
          Invoices
        </p>
      </div>
      <div id="payment-link" :class="[selected ? 'show' : '']" class="demo-box">
        <div class="payment-link-left left-box">
          <div class="left-box-header">
            <h4>Payment Link</h4>
          </div>

          <!-- <div class="link-upload-img">
            <h5>Link Image</h5>
            <input type="file" />
            <p class="upload-requirement">
              Max. image size should be less than 5MB
            </p>
          </div>
           -->
          <div class="link-dets">
            <h5>Payment Details</h5>
            <div class="link-dets-fields">
              <div class="field-wrapper">
                <label for="">Name</label><br />
                <input
                  type="text"
                  class="field-wrapper-input"
                  placeholder="Enter name"
                  maxlength="40"
                  v-model="paymentLinkData.paymentName"
                />
              </div>
              <div class="field-wrapper">
                <label for="">Description</label><br />
                <input
                  type="text"
                  class="field-wrapper-input"
                  placeholder="Enter description"
                  maxlength="40"
                  v-model="paymentLinkData.paymentDesc"
                />
              </div>
            </div>
          </div>

          <div class="link-item-dets">
            <h5>Item Details</h5>
            <div
              class="items item1"
              v-for="item in paymentLinkData.items"
              :key="item.id"
            >
              <!-- <input type="file" class="upload-img-square" /> -->
              <div class="item-upload">
                <div class="field-wrapper">
                  <label for="">Name</label><br />
                  <input
                    type="text"
                    class="field-wrapper-input field-wrapper-input-name"
                    placeholder="Enter name"
                    v-model="item.title"
                    maxlength="40"
                  />
                </div>
                <div class="field-wrapper">
                  <label for="">Price</label><br />
                  <input
                    type="number"
                    class="field-wrapper-input"
                    placeholder="0.00"
                    v-model="item.price"
                    maxlength="10"
                    onKeyPress="if(this.value.length==8) return false;"
                    step="0.01"
                    min="0"
                  />
                </div>
                <div class="field-wrapper field-wrapper-desc">
                  <label for="">Description</label><br />
                  <input
                    type="text"
                    class="field-wrapper-input field-wrapper-input-desc"
                    placeholder="Enter description"
                    v-model="item.desc"
                    maxlength="40"
                  />
                </div>
              </div>
            </div>

            <!-- <div class="items item1">
              <input type="file" class="upload-img-square" />
              <div class="item-upload">
                <div class="field-wrapper">
                  <label for="">Name</label><br />
                  <input
                    type="text"
                    class="field-wrapper-input field-wrapper-input-name"
                    placeholder="Enter name"
                  />
                </div>
                <div class="field-wrapper">
                  <label for="">Price</label><br />
                  <input
                    type="text"
                    class="field-wrapper-input"
                    placeholder="Enter price"
                  />
                </div>
                <div class="field-wrapper field-wrapper-desc">
                  <label for="">Description</label><br />
                  <input
                    type="text"
                    class="field-wrapper-input field-wrapper-input-desc"
                    placeholder="Enter description"
                  />
                </div>
              </div>
            </div> -->

            <!-- <h5
              class="text-btn"
              @click="addItem()"
              :class="[
                paymentLinkData.items.length < 5 ? '' : 'deactivated-btn',
              ]"
            >
              Add more item
            </h5> -->
          </div>

          <div class="link-opts">
            <h5>Options</h5>
            <div class="checkbox">
              <input
                type="checkbox"
                v-model="paymentLinkData.customFields[0].show"
              />
              <label for="">Custom field</label>
            </div>
            <div class="custom-fields custom-field1">
              <div class="custom-field">
                <div class="field-wrapper">
                  <label for="">Title</label><br />
                  <input
                    type="text"
                    class="field-wrapper-input field-wrapper-input-name"
                    placeholder="Enter name"
                    maxlength="40"
                    v-model="paymentLinkData.customFields[0].title"
                  />
                </div>
                <div class="field-wrapper">
                  <label for="">Type</label><br />
                  <select
                    name="input-type"
                    id="input-type"
                    class="field-wrapper-input"
                  >
                    <option value="text">Text</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
            </div>
            <!-- <h5 class="text-btn">Add more field</h5> -->
          </div>
          <div class="button">Create payment</div>
        </div>
        <div class="payment-link-right right-box">
          <div class="link-phone-mockup">
            <div class="link-phone-mockup-header">
              <div class="url">
                <img src="../assets/img/lock.png" alt="" />
                <p>checkout.tpay.com.my</p>
              </div>
            </div>
          </div>
          <div class="white-box link-payment-dets-mockup">
            <!-- <img src="" alt="" /> -->
            <p class="title">
              {{
                paymentLinkData.paymentName
                  ? paymentLinkData.paymentName
                  : "Payment name"
              }}
            </p>
            <p class="desc">
              {{
                paymentLinkData.paymentDesc
                  ? paymentLinkData.paymentDesc
                  : "Description"
              }}
            </p>
          </div>
          <div class="white-box price-mockup">
            <p class="title">To pay</p>
            <p class="price">MYR <span>0.00</span></p>
          </div>
          <div class="white-box items-mockup">
            <div class="items-dets-wrapper">
              <div
                class="items-dets"
                v-for="item in paymentLinkData.items"
                :key="item.id"
              >
                <div class="item">
                  <div class="items-name">
                    <p class="title">
                      {{ item.title ? item.title : "Item name" }}
                    </p>
                    <p class="desc">
                      {{ item.desc ? item.desc : "Description" }}
                    </p>
                    <input
                      type="number"
                      v-model="paymentLinkData.items[0].qty"
                      onKeyPress="if(this.value.length==8) return false;"
                    />
                  </div>
                  <div class="items-price">
                    MYR <span>{{ item.price ? item.price : "0.00" }} </span>
                  </div>
                </div>
              </div>
              <!-- <div class="items-dets">
                <img src="" alt="" />
                <div class="item">
                  <div class="items-name">
                    <p class="title">Payment name</p>
                    <p class="desc">Description</p>
                    <input type="text" />
                  </div>
                  <div class="items-price">MYR <span>0.00</span></div>
                </div>
              </div> -->
              <div class="line"></div>
              <div class="total-price">
                <h5>Total</h5>
                <h5>
                  MYR
                  <span>{{
                    paymentLinkData.items[0].price
                      ? paymentLinkData.items[0].price *
                        paymentLinkData.items[0].qty
                      : "0.00"
                  }}</span>
                </h5>
              </div>
            </div>
          </div>
          <div class="white-box link-payment-cust-mockup">
            <div class="cust-dets-wrapper">
              <h5>Customer details</h5>
              <div class="field-wrapper">
                <label for="">Email Address</label><br />
                <input
                  type="text"
                  class="field-wrapper-input"
                  placeholder="Enter email"
                  required
                />
              </div>
              <div class="field-wrapper">
                <label for="">Name</label><br />
                <input
                  type="text"
                  class="field-wrapper-input"
                  placeholder="Enter name"
                />
              </div>
              <div class="field-wrapper">
                <label for="">Phone Number</label><br />
                <input
                  type="text"
                  class="field-wrapper-input"
                  placeholder="Enter phone number"
                />
              </div>
              <div
                class="field-wrapper custom-field-input-wrapper"
                :class="[
                  { 'show-custom-field': paymentLinkData.customFields[0].show },
                ]"
              >
                <label for="">
                  {{
                    paymentLinkData.customFields[0].title.length
                      ? paymentLinkData.customFields[0].title
                      : "Custom field"
                  }}</label
                ><br />
                <input
                  type="text"
                  class="field-wrapper-input"
                  placeholder="Enter details"
                />
              </div>
              <div class="button">Pay now</div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="invoices"
        :class="[!selected ? 'show' : '']"
        class="invoice demo-box"
      >
        <div class="invoice-left left-box">
          <div class="left-box-header">
            <h4>Invoice</h4>
          </div>

          <!-- <div class="link-upload-img">
            <h5>Link Image</h5>
            <input type="file" />
            <p class="upload-requirement">
              Max. image size should be less than 5MB
            </p>
          </div>
           -->
          <div class="invoice-dets">
            <h5>Contact</h5>
            <div class="">
              <div class="field-wrapper">
                <select
                  name="input-type"
                  id="input-type"
                  class="field-wrapper-input"
                  @change="selectContact(selectedContactId)"
                  v-model="selectedContactId"
                >
                  <option
                    v-for="contact in invoiceData.contacts"
                    :key="contact.id"
                    :value="contact.id"
                  >
                    {{ contact.name }} ( {{ contact.email }} )
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="invoice-dets">
            <h5>Payment Details</h5>
            <div class="invoice-dets-field">
              <div class="field-wrapper">
                <label for="">Name</label><br />
                <input
                  type="text"
                  class="field-wrapper-input"
                  placeholder="Enter name"
                  maxlength="40"
                  v-model="invoiceData.itemName"
                />
              </div>
              <div class="field-wrapper">
                <label for="">Price</label><br />
                <input
                  type="number"
                  class="field-wrapper-input"
                  placeholder="0.00"
                  onKeyPress="if(this.value.length==8) return false;"
                  v-model="invoiceData.itemPrice"
                />
              </div>
            </div>
            <div class="field-wrapper">
              <label for="">Payment Methods</label>
            </div>
            <div class="invoice-payment-methods">
              <div class="checkbox">
                <input type="checkbox" />
                <label for="">FPX</label>
              </div>
              <div class="checkbox">
                <input type="checkbox" />
                <label for="">Credit Card</label>
              </div>
              <div class="checkbox">
                <input type="checkbox" />
                <label for="">Touch 'n Go eWallet</label>
              </div>
              <div class="checkbox">
                <input type="checkbox" />
                <label for="">GrabPay</label>
              </div>
            </div>
          </div>

          <div class="button">Create payment</div>
        </div>
        <div class="payment-link-right right-box">
          <div class="link-phone-mockup">
            <div class="link-phone-mockup-header">
              <div class="url">
                <img src="../assets/img/lock.png" alt="" />
                <p>checkout.tpay.com.my</p>
              </div>
            </div>
          </div>
          <div class="white-box price-mockup">
            <p class="title">To pay</p>
            <p class="price">MYR <span>0.00</span></p>
          </div>

          <div class="white-box items-mockup">
            <h5>Personal Details</h5>
            <div class="items-dets-wrapper">
              <!-- {{ paymentLinkData.items }} -->
              <div
                class="items-dets"
                v-for="item in paymentLinkData.items"
                :key="item.id"
              >
                <div class="item">
                  <div class="items-name">
                    <p class="title">Name</p>
                  </div>
                  <div class="items-price">
                    <span>{{ selectedName }}</span>
                  </div>
                </div>
              </div>
              <div
                class="items-dets"
                style="margin-bottom: 0"
                v-for="item in paymentLinkData.items"
                :key="item.id"
              >
                <div class="item">
                  <div class="items-name">
                    <p class="title">Email</p>
                  </div>
                  <div class="items-price">
                    <p>{{ selectedEmail }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="white-box items-mockup">
            <h5>Order Summary</h5>
            <div class="items-dets-wrapper">
              <!-- {{ paymentLinkData.items }} -->
              <div class="items-dets">
                <!-- <img src="" alt="" /> -->
                <div class="item">
                  <div class="items-name">
                    <p class="title">
                      {{
                        invoiceData.itemName
                          ? invoiceData.itemName
                          : "Item name"
                      }}
                    </p>
                  </div>
                  <div class="items-price">
                    MYR<span>
                      {{
                        invoiceData.itemPrice ? invoiceData.itemPrice : "0.00"
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- <div class="items-dets">
                <img src="" alt="" />
                <div class="item">
                  <div class="items-name">
                    <p class="title">Payment name</p>
                    <p class="desc">Description</p>
                    <input type="text" />
                  </div>
                  <div class="items-price">MYR <span>0.00</span></div>
                </div>
              </div> -->
              <div class="line"></div>
              <div class="total-price">
                <h5>Total</h5>
                <h5>
                  MYR<span>
                    {{ invoiceData.itemPrice ? invoiceData.itemPrice : "0.00" }}
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div class="white-box items-mockup">
            <h5>Payment Methods</h5>
            <div class="selected-payment">
              <p style="margin-bottom: 1rem">
                Selected payment: {{ selectedPayment }}
              </p>
            </div>
            <ul>
              <li>
                <ul class="selection-box" style="margin-bottom: 1rem">
                  <label for="">FPX</label>
                  <select
                    name="FPX"
                    id="FPX"
                    class="payment-selection"
                    @change="selectPayment(selectedPayment)"
                    v-model="selectedPayment"
                  >
                    <option
                      v-for="payment in paymentMethods[0]"
                      :key="payment[0]"
                      :value="payment.bank"
                    >
                      {{ payment.bank }}
                    </option>
                  </select>
                </ul>
              </li>
              <label for="">Credit Card</label>
              <li
                v-for="payment in paymentMethods[1]"
                :key="payment[0]"
                class="payment-selection"
                @click="selectPayment(payment.bank)"
                style="margin-bottom: 1rem"
              >
                {{ payment.bank }}
              </li>
              <li>
                <ul class="selection-box">
                  <label for="">e-Wallet</label>
                  <select
                    name=""
                    id=""
                    @change="selectPayment(selectedPayment)"
                    v-model="selectedPayment"
                    class="payment-selection"
                  >
                    <option
                      v-for="payment in paymentMethods[2]"
                      :key="payment[0]"
                    >
                      {{ payment.bank }}
                      {{ payment.id }}
                    </option>
                  </select>
                </ul>
              </li>
              <!-- <li
                v-for="payment in paymentMethods[1]"
                :key="payment[0]"
                class="payment-selection"
                @click="selectPayment(payment.bank)"
              >
                {{ payment.bank }}
              </li> -->
            </ul>
            <div class="button" style="width: auto">Pay now</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Demo",
  data() {
    return {
      selected: true,
      paymentSelected: true,
      selectedPayment: "",
      selectedName: "Jane Doe",
      selectedEmail: "janedoe@example.com",
      selectedContactId: -1,
      paymentLinkData: {
        paymentName: "",
        paymentDesc: "",
        items: [
          {
            id: 0,
            title: "",
            desc: "",
            qty: 1,
            price: "",
          },
        ],
        customFields: [
          {
            title: "",
            show: true,
          },
        ],
      },
      invoiceData: {
        itemName: "",
        itemPrice: "",
        contacts: [
          { id: 0, name: "Jane Doe", email: "janedoe@example.com" },
          { id: 1, name: "Faizal", email: "faizal@example.com" },
        ],
      },
      paymentMethods: [
        [
          { id: 1, bank: "Maybank" },
          { id: 2, bank: "CIMB Bank" },
        ],
        [{ id: 3, bank: "Credit Card" }],
        [
          { id: 4, bank: "GrabPay" },
          { id: 5, bank: "Boost" },
        ],
      ],
    };
  },
  methods: {
    toggle() {
      this.selected = !this.selected;
    },
    selectPayment(payment) {
      this.selectedPayment = payment;
    },
    selectContact(selectedContactId) {
      console.log(selectedContactId);
      this.selectedName = this.invoiceData.contacts[selectedContactId].name;
      this.selectedEmail = this.invoiceData.contacts[selectedContactId].email;
    },
    addItem() {
      if (this.paymentLinkData.items.length < 5)
        this.paymentLinkData.items.push(
          `{
            id:${this.paymentLinkData.items.length + 1},
            title: "",
            desc: "",
            qty: -1,
            price: "",
          }`
        );
    },
  },
  // computed: {
  //   renderSelectedPayment() {
  //     if(this.selectedPayment)
  //     console.log(payment);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/all.scss";
.selectedPayment {
  border: 1px solid #ff3377;
}

.show {
  display: none !important;
}
.demo-wrapper {
  text-align: center;
  padding: 2rem 1rem 0 1rem;
  @include wrapper;
  h2 {
    font-size: 40px;
    padding-bottom: 2rem;
  }
  p {
    word-wrap: break-word;
  }
  .carousel {
    display: flex;
    align-items: center;
    padding: 1rem 1.75rem;
    border-radius: 5rem;
    width: fit-content;
    margin: 0 auto;
    -webkit-box-shadow: 5px 5px 25px 0px rgba(230, 230, 230, 0.94);
    -moz-box-shadow: 5px 5px 25px 0px rgba(230, 230, 230, 0.94);
    box-shadow: 5px 5px 25px 0px rgba(230, 230, 230, 0.94);
    margin-bottom: 2rem;
    p {
      font-weight: 500;
      cursor: pointer;
      color: #bdbdbd;
    }
    .active {
      color: #ff3377;
    }
  }
  #payment-link-opt {
    padding-right: 1rem;
  }
  #invoices-opt {
    padding-left: 1rem;
  }
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="checkbox"]:focus,
  select:focus,
  .payment-selection:hover {
    outline: none !important;
    border: 1px solid #dfdfdf;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
  }
  input[type="text"],
  input[type="number"],
  input[type="checkbox"],
  select {
    font-family: "Jost";
    color: #4f4f4f;
    font-size: 14px;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    border: 1px solid #dfdfdf;
    padding: 9px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
  }
  input[type="checkbox"]:checked {
    background-color: #ff3377;
    border: 1px solid #ff3377;
  }
  input[type="checkbox"]:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #fefefe;
  }
  .checkbox {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    label {
      padding-left: 0.5rem;
    }
    // input {
    //   border: 1px solid #efefef;
    //   background-color: #ff3377;
    // }
  }
}

#payment-link {
  width: 100%;
}

.field-wrapper-input {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-size: 16px;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.5rem;
}

.field-wrapper {
  label {
    color: #8a8a8a;
    word-break: break-word;
  }
  margin-bottom: 0.75rem;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bdbdbd;
  opacity: 1; /* Firefox */
  font-size: 14px;
  font-family: "Jost";
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bdbdbd;
  font-size: 14px;
  font-family: "Jost";
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bdbdbd;
  font-size: 14px;
  font-family: "Jost";
}

.left-box {
  background-color: #fefefe;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #efefef;
  text-align: left;
  -webkit-box-shadow: 5px 5px 40px 0px rgba(245, 245, 245, 1);
  -moz-box-shadow: 5px 5px 40px 0px rgba(245, 245, 245, 1);
  box-shadow: 5px 5px 40px 0px rgba(245, 245, 245, 1);
  height: fit-content;
  h5 {
    color: #4f4f4f;
    font-size: 16px;
    font-weight: 500;
  }
  .left-box-header {
    background-color: #faf8f8;
    border-bottom: 1px solid #efefef;
    border-radius: 10px 10px 0 0;
    padding: 0.9rem 0;
    h4 {
      font-size: 18px;
      font-weight: 400;
      // display: inline-block;
      margin-left: 1rem;
    }
  }
}

.button {
  @include button2;
  width: 80%;
  margin: 1rem auto 1rem auto;
}

.right-box {
  .price-mockup {
    .title {
      color: #ff3377;
      font-size: 14px;
      font-weight: 500;
    }
    .price {
      font-size: 24px;
      font-weight: 500;
    }
  }
  h5 {
    color: #4f4f4f;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .items-mockup {
    .items-dets {
      display: flex;
      margin-bottom: 1.25rem;
      width: 100%;
      img {
        width: 20%;
        height: auto;
      }
    }
    .item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      // margin-left: 0.5rem;
      .items-name {
        width: 50%;
        .desc {
          font-size: 14px;
          color: #8a8a8a;
        }
        input {
          width: 30px;
          border: 1px solid #dfdfdf;
          border-radius: 5px;
          font-size: 14px;
          padding: 0.5rem;
          // box-sizing: border-box;
          // width: 100%;
          margin-top: 0.5rem;
        }
      }
      .items-price {
        width: 50%;
        text-align: right;
      }
    }
    .line {
      height: 1px;
      width: 100%;
      background-color: #dfdfdf;
    }
    .total-price {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 1rem 0 0rem 0;
      h5 {
        display: inline-block;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }
  .selection-box label {
    padding-bottom: 1rem;
  }
}

.payment-link-left {
  .link-dets-fields,
  .items {
    margin: 1rem 0 0.25rem 0;
  }

  .items {
    background-color: #faf8f8;
    border: 1px dashed #efefef;
    border-radius: 10px;
    padding: 1rem;
    label {
      font-size: 14px;
    }
  }

  // input[type="checkbox"]:checked + .custom-fields {
  //   display: none;
  // }

  .upload-img-square {
    box-sizing: border-box;
  }

  // .item-upload {
  // }

  .text-btn {
    @include textButton;
  }

  .deactivated-btn {
    color: #8a8a8a !important;
    &:hover {
      text-decoration: none;
    }
  }

  .link-upload-img,
  .link-dets,
  .link-item-dets,
  .link-opts {
    @include segment;
  }

  .upload-requirement {
    @include upload-requirement;
  }

  @media only screen and (min-width: 768px) {
    .field-wrapper {
      display: inline-block;
    }
    .link-dets-fields {
      display: flex;
    }
    .field-wrapper {
      width: 50%;
    }
    .field-wrapper-input {
      width: 90%;
    }
    .items {
      display: flex;
      .field-wrapper-desc {
        width: 100%;
      }
      .field-wrapper-input-desc {
        width: 95%;
      }
    }
    .upload-img-square {
      width: 30%;
    }
    .item-upload {
      width: 100%;
    }
  }
}

.payment-link-right {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #efefef;
  -webkit-box-shadow: 5px 5px 40px 0px rgba(245, 245, 245, 1);
  -moz-box-shadow: 5px 5px 40px 0px rgba(245, 245, 245, 1);
  box-shadow: 5px 5px 40px 0px rgba(245, 245, 245, 1);
  background-color: #faf8f8;
  box-sizing: border-box;
  padding-bottom: 1.5rem;
  .link-phone-mockup-header {
    background-color: #4f4f4f;
    border-radius: 10px 10px 0 0;
    padding: 0.5rem 0;
    p {
      color: white;
      font-size: 12px;
    }
  }
  .url {
    display: flex;
    padding: 0.25rem 3rem;
    background-color: #8a8a8a;
    width: fit-content;
    border-radius: 5px;
    align-items: center;
    margin: 0 auto;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .white-box {
    @include white-box;
  }
  .link-payment-dets-mockup {
    img {
      height: 100px;
      width: 100px;
    }
    .desc {
      color: #8a8a8a;
      font-size: 14px;
    }
  }

  .link-payment-cust-mockup {
    .button {
      @include button2;
      width: 100%;
      margin: 1.5rem auto 0 auto;
      border-radius: 10px;
      box-sizing: border-box;
    }
    .custom-field-input-wrapper {
      display: none;
    }
    .show-custom-field {
      display: block !important;
    }
  }
}

.invoice {
  .invoice-dets {
    padding: 1rem;
    border-bottom: 1px solid #efefef;
  }
  .field-wrapper {
    margin: 1rem 0 0.25rem 0;
  }

  .payment-selection {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    font-size: 16px;
    padding: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin-top: 0.5rem;
  }

  @media only screen and (min-width: 768px) {
    .invoice-dets-field {
      display: flex;
    }
    .field-wrapper {
      width: 50%;
      input {
        width: 90%;
      }
    }
    .invoice-payment-methods {
      display: flex;
    }
    .checkbox {
      margin-right: 0.75rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .demo-box {
    display: flex;
    justify-content: space-around;
  }
  .left-box {
    width: 50%;
  }
  .right-box {
    width: 30%;
  }
}
</style>
