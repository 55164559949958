<template>
  <div class="home">
    <div class="hero-div">
      <div class="hero-div-wrapper">
        <div class="hero-div-left">
          <h4>Zero fees. Pay per use.</h4>
          <h1>
            Create and Publish Your Digital Catalogue Complete With A Checkout
            Page With <span>Zero I.T!</span>
          </h1>
          <div class="button2" @click="openPopUp()">
            Create a high-converting digital catalogue today!
          </div>
        </div>
        <div class="hero-div-right">
          <img src="../assets/img/hero-div1-img1.webp" alt="" />
        </div>
      </div>
    </div>
    <div class="features-div">
      <div class="features-wrapper">
        <div class="feature feature1">
          <img src="../assets/img/feature1.png" alt="" />
          <p>
            No coding skills are required. Focus on growing your online business
          </p>
        </div>
        <div class="feature feature2">
          <img src="../assets/img/feature2.png" alt="" />
          <p>
            Accept all e-commerce favorite payment methods including FPX, Debit/
            Credit Card, and e-Wallet
          </p>
        </div>
        <div class="feature feature3">
          <img src="../assets/img/feature3.png" alt="" />
          <p>
            Share your product or service listing that comes with a built-in
            checkout flow
          </p>
        </div>
        <div class="feature feature4">
          <img src="../assets/img/feature4.png" alt="" />
          <p>
            Add payment links to your business social media accounts. Customers
            can click, browse and make online payments to purchase
          </p>
        </div>
      </div>
    </div>
    <div class="steps-div">
      <div class="steps-wrapper">
        <img src="../assets/img/step1.webp" alt="" />
        <img src="../assets/img/step2.webp" alt="" />
        <img src="../assets/img/step3.webp" alt="" />
      </div>
    </div>
    <!-- <div class="testimonial-div">
      <div class="testimonial-wrapper">
        <div class="testimonial testimonial-1">
          <p>“Review Review Review Review”</p>
          <h5>Review 1</h5>
        </div>
      </div>
    </div> -->
    <Demo />
    <div class="videos-div">
      <div class="videos-wrapper">
        <h2>
          Watch these videos and find out how you can start accepting payments
          on your business social media accounts today! Website or coding skills
          not required!
        </h2>
        <div class="product invoices">
          <div class="right-product">
            <div class="desc desc1">
              <h3>Invoices</h3>
              <div class="point desc1-point desc1-point1">
                <img src="../assets/img/p1.png" alt="" />
                <p>Send a one-off, custom payment request to a customer</p>
              </div>
              <div class="point desc1-point desc1-point2">
                <img src="../assets/img/p2.png" alt="" />
                <p>
                  Control the payment method you offer from your dashboard (FPX,
                  card, or e-wallet)
                </p>
              </div>
              <div class="point desc1-point desc1-point3">
                <img src="../assets/img/p3.png" alt="" />
                <p>
                  The customer receives an email with a one-time payment link.
                  Easy one-click to payment page
                </p>
              </div>
            </div>
          </div>
          <div class="left-product invoices-video">
            <video width="100%" height="auto" controls autoplay>
              <source
                src="https://official-tekkis.b-cdn.net/t-Pay%20Invoice%20Landing%20Page%20video%20(1).MP4"
                type="video/mp4"
              />
              <source src="movie.ogg" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div class="product payment-link">
          <div class="right-product">
            <div class="desc desc2">
              <h3>Payment Link</h3>
              <div class="point desc2-point desc2-point1">
                <img src="../assets/img/p4.png" alt="" />
                <p>Shareable URL link to your digital business catalog</p>
              </div>
              <div class="point desc2-point desc2-point2">
                <img src="../assets/img/p5.png" alt="" />
                <p>List your business products and services</p>
              </div>
              <div class="point desc2-point desc2-point3">
                <img src="../assets/img/p6.png" alt="" />
                <p>Accept payments within the URL link</p>
              </div>
              <div class="point desc2-point desc2-point4">
                <img src="../assets/img/p7.png" alt="" />
                <p>Reuse the link multiple times</p>
              </div>
            </div>
          </div>
          <div class="left-product payment-link-video">
            <video width="100%" height="auto" controls autoplay>
              <source
                src="https://official-tekkis.b-cdn.net/t-Pay%20Payment%20Link%20Landing%20Page%20video%20(1).MP4"
                type="video/mp4"
              />
              <source src="movie.ogg" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-div">
      <div class="footer-wrapper">
        <div class="footer-desc">
          <h3>Zero fees. Pay per use.</h3>
          <div class="button2" @click="openPopUp()">
            Get your free demo access now!
          </div>
        </div>
        <img src="../assets/img/footer-bg.webp" alt="" />
      </div>
    </div>
    <div class="copyright-div">
      <div class="copyright-wrapper">
        <img src="../assets/img/tpay-logo.png" alt="" />
        <p>Payment acceptance from any channel that is secure and reliable.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Demo from "../elements/Demo.vue";

export default {
  name: "Home",
  // data() {
  //   return {
  //     videoLink:
  //       "https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFT311s3EY&#x2F;watch?embed",
  //     videoLink2:
  //       "https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFT311s3EY&#x2F;watch?utm_content=DAFT311s3EY&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link",
  //   };
  // },
  components: {
    Demo,
  },
  methods: {
    openPopUp() {
      this.$emit("openPopUp");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/all.scss";
/* Hero */
.hero-div {
  background-image: url("../assets/img/tpay-div1-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-div-left {
  padding: 0 1rem;
  h4 {
    color: #4f4f4f;
    font-size: 20px;
  }
  h1 {
    color: #4f4f4f;
    font-size: 36px;
    font-weight: 500;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    line-height: 50px;
  }
  .button2 {
    @include button2;
    width: fit-content;
    line-height: 25px;
    margin-bottom: 1rem;
  }
}

.hero-div-right {
  img {
    height: auto;
    width: 100%;
  }
}

.hero-div-wrapper {
  @include wrapper;
  padding-top: 2rem;
}

.features-div {
  background-color: #faf8f8;
}

@media only screen and (min-width: 768px) {
  .hero-div-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .hero-div-left {
    width: 55%;
    h1 {
      font-size: 48px;
      font-weight: 400;
      line-height: 65px;
    }
  }
  .hero-div-right {
    margin-top: 0;
  }
  .hero-div-right img {
    margin-top: 0;
  }
}

/* Feature */
.features-div {
  padding: 2rem 1rem 1rem 1rem;
  .feature {
    margin-bottom: 2rem;
  }
}

.feature img {
  height: 25px;
  width: 25px;
  margin-bottom: 1rem;
}

.features-wrapper {
  @include wrapper;
}

/* Steps */

.steps-wrapper img {
  width: 100%;
  vertical-align: bottom;
}

@media only screen and (min-width: 768px) {
  .features-div {
    .features-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .feature {
      width: 22%;
    }
    p {
      line-height: 1.5rem;
    }
  }
  .steps-wrapper {
    display: flex;
    img {
      width: 33.333%;
    }
  }
}

/* Testimonial */
.testimonial-div {
  background-image: url("../assets/img/testimonial-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: 3rem 1rem;
}

.testimonial {
  text-align: center;
  h5 {
    font-weight: 500;
  }
}

@media only screen and (min-width: 768px) {
  .testimonial p {
    width: 60%;
    margin: 0 auto 1rem auto;
  }
}

.testimonial-wrapper {
  @include wrapper;
}

/* Videos */
.videos-wrapper h2 {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 3rem;
}

.videos-wrapper {
  @include wrapper;
  padding: 2rem 1rem;
  h3 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
}
.product img {
  width: 50px;
  height: 50px;
}

.point {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .videos-wrapper h2 {
    font-size: 36px;
    width: 65%;
    margin: 3rem auto 5rem auto;
  }
  .videos-wrapper {
    h3 {
      font-size: 30px;
    }
  }
  .product {
    display: flex;
    width: 80%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }

  .product .desc {
    width: 90%;
  }
  .left-product {
    width: 50%;
    text-align: center;
  }
  .left-product video {
    max-width: 375px;
  }
  .payment-link {
    flex-direction: row-reverse;
  }
  .invoices {
    margin-bottom: 3rem;
  }
  .desc2 {
    width: 100% !important;
  }
}

/* Footer */
.footer-div {
  background-color: #53aafc;
  padding-top: 2rem;
  text-align: center;
}

.footer-desc {
  h3 {
    font-size: 36px;
    color: #fff;
  }
}

.footer-wrapper {
  @include wrapper;
  img {
    width: 100%;
  }
}

.button2 {
  @include button2;
  width: fit-content;
  margin: 0.5rem auto 0 auto;
}
@media only screen and (min-width: 768px) {
  .footer-div {
    text-align: left;
    padding-top: 0rem;
  }
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4rem;
    img {
      width: 60%;
      max-width: 780px;
    }
  }
  .button2 {
    margin-left: 0;
  }
}

.left-product video {
  width: 100%;
  margin: 2rem;
}

video {
  -webkit-box-shadow: 10px 10px 61px 0px rgba(222, 222, 222, 1);
  -moz-box-shadow: 10px 10px 61px 0px rgba(222, 222, 222, 1);
  box-shadow: 10px 10px 61px 0px rgba(222, 222, 222, 1);
  max-width: 375px;
}

/* Copyright */

.copyright-div {
  background-color: #12070b;
  padding: 1rem 1rem;
  img {
    width: 60px;
    height: auto;
  }
  p {
    font-weight: 500;
  }
}

.copyright-wrapper {
  @include wrapper;
}

@media only screen and (min-width: 768px) {
  .copyright-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
