<template>
  <div class="header">
    <div class="header-wrapper">
      <img src="../assets/img/tpay-logo.png" alt="" />
      <div class="btn-1" @click="openPopUp()">Book demo</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openPopUp() {
      this.$emit("openPopUp");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/all.scss";

.header {
  height: 70px;
  background-color: #fefefe;
  border-bottom: 1px solid #efefef;
}

.header-wrapper {
  @include wrapper;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.btn-1 {
  @include button1;
  padding: 0.75rem 1rem;
}

img {
  height: 45px;
  width: auto;
}
</style>
