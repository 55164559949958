<template>
  <div class="contact-div">
    <div class="contact-wrapper">
      <div class="close" @click="closePopup()">
        <img src="../assets/img/close-btn.png" alt="" />
      </div>
      <h2>
        I’d like to start sending digital catalogs with payment links to my
        customers
      </h2>
      <form
        accept-charset="UTF-8"
        v-on:submit.prevent="onSubmit()"
        method="POST"
        enctype="multipart/form-data"
        id="ajaxForm"
      >
        <div class="contact-fields">
          <label for="">Name</label>
          <input
            type="text"
            placeholder="Enter name"
            maxlength="70"
            v-model="fullname"
            required
          /><br />
          <label for="">Company Email</label>
          <input
            type="email"
            placeholder="Enter company email"
            maxlength="70"
            v-model="workemail"
            required
          /><br />
          <label for="">Designation</label>
          <input
            type="text"
            placeholder="Enter your designation"
            maxlength="70"
            v-model="designation"
            required
          /><br />
          <label for="">Phone Number</label>
          <input
            type="number"
            placeholder="Enter phone number"
            maxlength="70"
            v-model="phonenumber"
            required
          />
        </div>
        <div class="d-none" :class="modalSuccessbox ? 'formMessage' : ''">
          Thanks for submitting!
        </div>
        <div class="d-none" :class="modalFailbox ? 'formMessage' : ''">
          Oops, please try again.
        </div>
        <button class="btn" id="submitbtn" type="submit">
          <div class="" :class="!loading ? '' : 'hideBtn'">Submit</div>
          <div class="loader" :class="loading ? '' : 'hideBtn'"></div>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  methods: {
    closePopup() {
      this.$emit("closePopUp");
    },
    onSubmit() {
      // this.recaptcha();

      // console.log(this.agreebox);
      if (this.agreebox != "") {
        this.message = "";
        document.getElementById("submitbtn").disabled = true;
        const formData = new FormData();
        formData.append("fullname", this.fullname);
        formData.append("workemail", this.workemail);
        formData.append("designation", this.designation);
        formData.append("phonenumber", this.phonenumber);
        formData.append("formSource", this.formSource);
        this.loading = true;
        document.getElementById("submitbtn").disabled = true;
        axios
          .post("https://emailapi.tekkis.my/Web/email/send", formData, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.modalSuccessbox = true;
              this.fullname = "";
              this.workemail = "";
              this.designation = "";
              this.phonenumber = "";
              this.loading = false;
            } else {
              this.modalFailbox = true;
              this.loading = false;
            }
            document.getElementById("submitbtn").disabled = false;
          });
      }
    },
  },
  data() {
    return {
      fullname: "",
      workemail: "",
      designation: "",
      phonenumber: "",
      formSource: "tpayLandingPage",
      modalSuccessbox: false,
      modalFailbox: false,
      loading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/all.scss";
.hideBtn {
  display: none;
}
.loader {
  border: 4px solid #ffd8d6;
  opacity: 1;
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 0.5s linear infinite; /* Safari */
  animation: spin 0.5s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.d-none {
  display: none;
}
.formMessage {
  display: block;
}
.contact-div {
  background-color: rgba($color: #000000, $alpha: 0.5);
  width: 100%;
  height: 100%;
}
.btn {
  width: 100%;
  background-color: #ff3377;
  padding: 0.5rem 0.75rem;
  text-align: center;
  color: #fefefe;
  border-radius: 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0px;
  font-family: "Jost";
}
.contact-wrapper {
  background-color: #fefefe;
  max-width: 500px;
  border-radius: 10px;
  margin: 1rem;
  h2 {
    font-size: 24px;
    margin-bottom: 2rem;
  }
  padding: 1rem 2rem;
}

label {
  display: block;
  font-size: 16px;
}

input[type="text"],
input[type="number"],
input[type="email"] {
  background-color: #fefefe;
  border-radius: 5px;
  font-size: 16px;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.5rem;
  font-family: "Jost";
  color: #4f4f4f;
  margin-bottom: 1rem;
  outline: none !important;
  border: 1px solid #dfdfdf;
}

:focus-visible {
  outline: 0px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bdbdbd;
  opacity: 1; /* Firefox */
  font-size: 14px;
  font-family: "Jost";
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bdbdbd;
  font-size: 14px;
  font-family: "Jost";
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bdbdbd;
  font-size: 14px;
  font-family: "Jost";
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus {
  outline: none !important;
  border: 1px solid #dfdfdf;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}

.close {
  text-align: right;
  img {
    width: 20px;
    height: 20px;
    margin: 1rem 0 1rem 1rem;
    cursor: pointer;
  }
}
@media only screen and (min-width: 768px) {
  .contact-wrapper {
    margin: 5rem auto 0 auto;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
