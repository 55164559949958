<template>
  <div id="app">
    <ContactForm
      class="contactPopup"
      :class="[popUp ? 'showPopup' : '']"
      @closePopUp="changePopUp"
    />
    <HeaderDiv @openPopUp="changePopUp" />
    <Home @openPopUp="changePopUp" />
  </div>
</template>

<script>
/* eslint-disable */
import HeaderDiv from "./components/HeaderDiv.vue";
import Home from "./components/Home.vue";
import ContactForm from "./elements/ContactForm.vue";

export default {
  name: "App",
  components: {
    Home,
    HeaderDiv,
    ContactForm,
  },
  data() {
    return {
      popUp: false,
    };
  },
  methods: {
    changePopUp() {
      if (!this.popUp) {
        this.popUp = true;
      } else {
        this.popUp = false;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Jost", Helvetica, Arial, sans-serif;
  color: #4f4f4f;
}

.contactPopup {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}

.showPopup {
  display: block;
}
</style>
